.cmp.seminar-detail {
  padding-top : rem-calc(70);
  border-top  : 8px solid $primary-color;

  h1 {
    color       : $primary-color;
    line-height : 1;
  }

  p {
    font-size     : rem-calc(18);
    margin-bottom : rem-calc(35);
  }

  .category {
    font-size : rem-calc(14);
  }

  .default-field {
    // used for honeypot fields
    display : none;
  }

  .target-group {
    display    : block;
    width      : fit-content;
    background : $medium-gray;
    padding    : rem-calc(4 12);
    font-size  : rem-calc(16);
  }

  .button {
    height          : 43px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    text-align      : center;
  }

  .seminar-teaser {
    margin-bottom : 0;
  }
}

.registration-form-select {
  margin-bottom : rem-calc(12);
  display       : none;

  &.show {
    display : block;
  }

  .button {
    margin-bottom : 0;
    margin-right  : rem-calc(12);
    margin-left   : rem-calc(12);
    background    : $light-gray;

    &.active {
      background : $secondary-color;
    }
  }
}
