.mobile-widget-container {
  position        : fixed;
  right           : 0;
  bottom          : 0;
  z-index         : 9999;
  display         : flex;
  width           : 100%;
  justify-content : space-between;
  grid-gap        : 15px;

  .widget {
    width           : 100%;
    background      : $white;
    padding-left    : rem-calc(12);
    height          : 50px;
    margin-bottom   : 0;
    display         : flex;
    align-items     : center;
    justify-content : center;
    transition      : .3s ease-in-out;
    font-weight     : 400;
    color           : $primary-color;

    box-shadow      : 0px 10px 6px rgba(42, 118, 134, 0.15);

    svg {
      width        : 28px;
      margin-right : .5rem;

      path, line, rect {
        stroke : $primary-color;
      }
    }

    &:hover {
      transform : translateX(0);

    }
  }

  @include breakpoint(large) {
    display : none;
  }
}



