.cmp-testimonials {
  background-color : $light-gray;
  padding          : rem-calc(55 0 40);
  font-size        : rem-calc(18);

  img {
    width         : 170px;
    border-radius : 85px;
    margin-bottom : rem-calc(25);
  }

  p {
    margin-top  : .5rem;
    font-weight : 900;
    color       : $third-color;
    @include breakpoint(large) {
      margin-bottom : 0;
    }
  }
}
