.cmp.content {
  > .grid-container > .grid-x {
    justify-content : center;

    > .small-8.cell .small-8, > .small-6.cell .small-6 {
      width   : 100%;
      padding : 0;
    }
  }

  figure {
    &.float-left {
      img {
        margin: rem-calc(0 15 10 0);
      }
    }
    &.float-right {
      margin: rem-calc(0 0 10 15);
    }
    &.top {
      margin: rem-calc(0 0 10 0);
    }
  }

  .grid-layout {
    img {
      width: 100%;
    }
    p {
      word-break: break-word;
    }
  }


  .thumbnail {
    margin  : 0;
    padding : rem-calc(5 0 5 0);
    @include breakpoint(medium down) {
      padding : 0;
      img {
        width : 100%;
      }
    }

    &.pull-left {
      padding   : rem-calc(5 25 5 0);
      max-width : 50%;
    }

    &.pull-right {
      padding   : rem-calc(5 0 5 25);
      max-width : 50%;
    }

    @include breakpoint(medium down) {
      &.pull-left,
      &.pull-right {
        padding   : rem-calc(5 0 15 0);
        max-width : 100%;
      }

      display : block;
      width   : 100%;
      padding : rem-calc(5 0 15 0);
      img {
        padding : 0;
        width   : 100%;
      }
    }
  }

  .bg-highlight {
    background            : $secondary-color;
    background-image      : url('{{path href="media/base/img/highlight.svg"}}');
    background-repeat     : no-repeat;
    background-position-x : 98%;
    background-position-y : 10%;

    h2 {
      font-weight : 800;
    }

    .cell.auto {
      padding : rem-calc(25);
      @include breakpoint(large) {
        padding : rem-calc(35 100 35 35);
      }
    }
  }

  .bg-grey {
    background            : $light-gray;
    background-image      : url('{{path href="media/base/img/highlight-yellow.svg"}}');
    background-repeat     : no-repeat;
    background-position-x : 98%;
    background-position-y : 10%;

    h2 {
      font-weight : 800;
    }

    .cell.auto {
      padding : rem-calc(25);
      @include breakpoint(large) {
        padding : rem-calc(35 100 35 35);
      }
    }
  }

  .subheadline {
    line-height    : 1;
    font-size      : rem-calc(14);
    text-transform : unset;
    color          : $dark-gray;
    margin         : rem-calc(0 0 10);
    padding        : 0;
  }
}

.caption {
  text-align    : right;
  margin        : 0;
  font-size     : rem-calc(12);
  font-style    : italic;
  font-weight   : normal;
  color         : $medium-gray;
  padding-right : rem-calc(25);
}

.cmp.downloads {
  .download-item {
    &:first-child {
      border-top : 1px solid $light-gray;
    }

    border-bottom : 1px solid $light-gray;
    padding       : rem-calc(10 0 10);
    @include breakpoint(medium down) {
      text-align  : center;
      align-items : center;
    }

    figcaption {
      display : none;
    }

    img {
      padding-top : 0;
      width       : 75px;
      OBJECT-FIT  : contain;
      HEIGHT      : 112px;
      @include breakpoint(medium down) {
        height : 80px;
      }
    }

    .button {
      margin : rem-calc(0 0 0 12);
      @include breakpoint(medium down) {
        display : block;
      }
    }

    .download-title {
      font-size      : rem-calc(18);
      display        : block;
      padding-top    : rem-calc(10);
      padding-bottom : rem-calc(10);
      color          : $primary-color;
      font-weight    : 900;
    }

  }

}
