a.dropdown-btn, button.dropdown-btn{
    position: relative;
    background-color: $white!important;
    color: $black!important;
    padding-right: rem-calc(35);
    small{
        color: $medium-gray;
        padding-left: 35px;
        @include breakpoint(medium down){
            display: block;
            text-align: center;
            padding: 5px 0 0 0;
        }

    }
        &.blue{
            background-color: $primary-color;
            color: $black;
        }
    &:after{
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: inset 6px;
        content: '';
        border-bottom-width: 0;
        border-top-style: solid;
        border-color: $black transparent transparent;
        right: 9px;
        left: auto;
        margin-top: -9px;
    }
&:hover, &:focus, &:active{
    background-color: $white;
    color: $black;

}
}
.dropdown-pane{
    color: #000;
    width: 480px;
    max-width: 100%;
    background-color: transparent;

}
// dropdown list
ul.dropdown-list {
    border-radius: 8px;
    background-color: $white;
    margin: 0;
    overflow: hidden;
    text-align: left;
    box-shadow: 0 3px 21px 0 rgba(3,19,29,0.64);
    max-height: 80vh;
    overflow-y: scroll ;
    .dropdown-item {
        padding: rem-calc(8 12);
        small{
            float: right;
            @include breakpoint(medium down){
                float: none;
            }
        }
        &:hover, &:focus, &:active {
            background-color: lighten($primary-color, 20%);
            cursor: pointer;
            color: $black;
            a{
                color: $black;
            }
        }
    }
}
