.cmp.header-slideshow {
  position : relative;

  img {
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    object-fit : cover;
    object-position : 50% 15%;
    z-index    : 1;
    @include breakpoint(small only) {
      object-position : 63% 50%;
    }

  }

  &.header-big {
    .content-container {
      height : 80vh;
      @include breakpoint(medium) {
        height : 52vh;
      }
    }
  }

  .content-container {
    display     : flex;
    align-items : center;

    height : 70vh;
    @include breakpoint(medium) {
      height      : 40vh;
    }

    position    : relative;
    //padding     : rem-calc(0 80);
    z-index     : 2;

    > .grid-x {
      width : 100%;
    }

    @include breakpoint(small only) {
      padding : rem-calc(0 36);
    }

    h1 {
      font-size   : rem-calc(40);
      line-height : 1.1;
      font-weight : 400;
      @include breakpoint(xlarge) {
        // line-height : 1.1;
      }
    }

    @include breakpoint(medium down) {
      height : calc(100vh - 88px);
      h1 {
        font-size   : rem-calc(40);
        font-weight : 400;
      }
    }

    &.white {
      color : $white;
    }

    p {
      font-size   : rem-calc(18);
      font-weight : 600;
      line-height : 29px;
    }

    .svg-wrapper {
      position  : absolute;
      top       : 10%;
      left      : 80%;
      transform : scale(.7) translate(0, -50%);
      z-index   : 1;
      @include breakpoint(medium down) {
        position  : absolute;
        top       : 30%;
        left      : -60%;
        transform : translate(0, -50%);
      }

      svg {
        position : absolute;
        top      : 0;
        opacity  : 0.7;

        &.back {
          animation : spin 8s ease-in-out infinite;
          top       : -2px;
        }

        &.front {
          animation : spinOff 8s ease-in-out infinite;

        }
      }
    }

    .txt-container {
      position : relative;
      z-index  : 2
    }
  }
}

@keyframes spin {
  from {
    transform : rotate(2deg);
  }
  50% {
    transform : rotate(20deg);
    top       : 1px;

  }
  to {
    transform : rotate(2deg);
  }
}

@keyframes spinOff {
  from {
    transform : rotate(3deg);
  }
  50% {
    transform : rotate(-35deg);

  }
  to {
    transform : rotate(3deg);
  }
}
