.cmp.contact-request {
  position   : relative;
  background : $primary-color;
  color      : $white;
  padding    : rem-calc(70 0 60);
  z-index    : 1;
  overflow   : hidden;

  .grid-container {
    position : relative;
    z-index  : 1;
  }

  label {
    color : $white
  }

  a {
    color       : $secondary-color;
    font-weight : 900;
  }

  button {
    border-radius  : 25px;
    text-transform : uppercase;
    font-size      : rem-calc(14);
    color          : $primary-color;
  }

  .ornament {
    position : absolute;
    left     : -10%;
    bottom   : 5%;
    @include breakpoint(medium down) {
      width  : 250px;
      height : auto;
      bottom : 10px;
    }

  }
}
