.seminar-list {
  .seminar-teaser {
    position : relative;
    margin-bottom: rem-calc(40);
    .top-title {
      font-size: rem-calc(14);
      margin-bottom: rem-calc(3);
    }
    .target-group {
      background: $medium-gray;
      width: fit-content;
      padding: rem-calc(3 16 2);
    }
    h4 {
      text-transform: initial;
      color: $primary-color;
      margin: rem-calc(10 0);
      font-weight: 700;
    }
    p {
      line-height: 1.7;
    }
    .info-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      background: $secondary-color;
      padding: rem-calc(10 0);
      margin-bottom: rem-calc(16);

      margin-top: auto;
      .info-element {
        display: flex;
        align-items: center;
        line-height: 0;
        margin: rem-calc(5 10);
        &.price {
          font-weight: 800;
          color: $primary-color;
        }
        img {
          margin-right: rem-calc(6);
        }
      }
    }
    @include breakpoint(medium) {
      padding: rem-calc(0 70);
      .info-wrap {
        padding: rem-calc(10 16);
        border-radius: rem-calc(50);
      }
    }
    @include breakpoint(large) {
      padding: initial;
      .circles {
        position : absolute;
        top: 0;
        width: 100px;
        height: auto;
        z-index: -1;
      }
      &:nth-child(odd) {
        padding-right: 55px;
        .circles {
          right: 70px;
        }
      }
      &:nth-child(even) {
        padding-left: 55px;
        border-left: 1px solid $third-color;
        .circles {
          right: 0;
        }
      }
      .info-wrap {
        justify-content: space-between;
        .info-element {
          margin: initial;
        }
      }
    }
    @include breakpoint(xlarge) {
      .info-wrap {
        padding: rem-calc(10 26);
      }
    }
  }
}
