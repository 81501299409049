.image-gallery{
    .cell img{
        width: 100%;
    }
    a{
        display: block;
        position: relative;
        background-image: none;
        transition: all .3s ease-in-out;

        &:hover{
            img{
                transition: all .3s ease-in-out;
                transform: scale(1.1)  rotate(1deg);
                box-shadow: 0 5px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -2px rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.12);
                background-position: 80% 40%;
                z-index: 2;
            }
        }
        &:focus, &:active{
            img{
                transform: scale(1)  rotate(0);
            }
        }


    }
    @include breakpoint(small only){
        &.grid-container{
            padding-right: 2.33333rem;
            padding-left: 2.33333rem;
        }
        .grid-margin-x.small-up-2>.cell {
            width: calc(50% - 14px);
            margin: 7px;
        }
    }

    .caption{
        position: absolute;
        top: 100%;
        right: 0;
        max-width: 100%;
    }
}
