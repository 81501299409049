/*
 * 	Default theme - Owl Carousel CSS File
 */

//nav

$nav-color: $black !default;
$nav-color-hover: $black !default;
$nav-font-size: 35px !default;
$nav-rounded: 0 !default;
$nav-margin: 0 !default;
$nav-padding: 16px 10px !default;
$nav-background: $primary-color !default;
$nav-background-hover: darken($primary-color, 20%)!default;
$nav-disabled-opacity: 0.5 !default;

//dots

$dot-width: 30px !default;
$dot-height: 5px !default;
$dot-rounded: 0 !default;
$dot-margin: 0 5px !default;
$dot-background: $black !default;
$dot-background-active: $light-gray !default;





/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}



.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {

}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

/* No Js */
.no-js .owl-carousel {
    display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
    transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
    /**
              This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
              calculation of the height of the owl-item that breaks page layouts
           */
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
}


.owl-carousel {
    .text-block {
        background-color: $light-gray;
        padding: rem-calc(55 70 30 30);
        @include breakpoint (large down) {
            padding: rem-calc(30 30 30 30);
        }
        @include breakpoint (medium down) {
            background-color: transparent;
            padding-right: 1.42857rem;
            padding-left: 1.42857rem;
        }
    }
    .read-more{

        @include breakpoint (small only) {
           display: none;
        }

    }


}


.slider-theme {
    // Styling Next and Prev buttons


    .owl-nav {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    width: 100%;
        margin-top: 10px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        height: 50px;

        @include breakpoint(medium down){
          width: 100%;
        }
        @include breakpoint(small only){
            display: none;
        }

        [class*='owl-'] {
            color: $nav-color;
            font-size: $nav-font-size;
            margin: $nav-margin;
            padding: $nav-padding;
            background: $nav-background;
            display: inline-block;
            cursor: pointer;
            border-radius: 3px;
            line-height: 0.9;
            transition: all .3s ease-in-out;
            &:hover {
                background: $nav-background-hover;
                color: $nav-color-hover;
                text-decoration: none;
            }
        }
        .disabled {
            opacity: $nav-disabled-opacity;
            cursor: default;
        }
        .owl-prev{
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
        .owl-next{
            position: absolute;
            right: 0;
            border-radius: 8px 0 0 8px;
        }
    }


    // Styling dots
    .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        z-index: 1;
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%);
        @include breakpoint(medium down){
            bottom: 0;

        }


        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;
            background-color: transparent!important;
            span {
                width: $dot-width;
                height: $dot-height;
                margin: $dot-margin;
                background: $dot-background;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: $dot-rounded;
            }

            &.active,
            &:hover {
                span {
                    background: $dot-background-active;
                }
            }
        }
    }

    .border-radius{
        overflow: hidden;
        border-radius: 25px 0  25px 0;
    }
    &.header-slider-onethird{
        .owl-nav, .owl-dots {
            width: 66.66667%;
            @include breakpoint(medium down){
                width: 100%;
            }
        }
        .owl-dots{
            left: 35%;
            @include breakpoint(medium down){
                left: 50%;
            }
        }
    }
}
.header-slider.grid-container{
    @include breakpoint(medium down){
        padding: 0;
    }
}



.owl-carousel.full-width{
    .owl-nav {
        width: 100%;
        position: absolute;
        top: 35%;
    }
        .owl-dots {
            width: 100%;
            position: absolute;
            top: 78%;
            bottom: initial;
            @include breakpoint(medium down){
                position: relative;
                margin-top: 1rem;
                top: 0;
            }
        }

    h3{
        margin-bottom: 0;
    }
    .text-block{
        background-color: transparent;
        padding: rem-calc(35 0 0);
        max-height: 115px;
        @include breakpoint(medium down){
            max-height: initial;
        }
        overflow-y: scroll;
        overflow-x: hidden;
          @include breakpoint(medium down){
              padding-right: 1.42857rem;
              padding-left: 1.42857rem;
          }


    }
}

