//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Flexbox Utilities
//  20. Forms
//  21. Label
//  22. Media Object
//  23. Menu
//  24. Meter
//  25. Off-canvas
//  26. Orbit
//  27. Pagination
//  28. Progress Bar
//  29. Prototype Arrow
//  30. Prototype Border-Box
//  31. Prototype Border-None
//  32. Prototype Bordered
//  33. Prototype Display
//  34. Prototype Font-Styling
//  35. Prototype List-Style-Type
//  36. Prototype Overflow
//  37. Prototype Position
//  38. Prototype Rounded
//  39. Prototype Separator
//  40. Prototype Shadow
//  41. Prototype Sizing
//  42. Prototype Spacing
//  43. Prototype Text-Decoration
//  44. Prototype Text-Transformation
//  45. Prototype Text-Utilities
//  46. Responsive Embed
//  47. Reveal
//  48. Slider
//  49. Switch
//  50. Table
//  51. Tabs
//  52. Thumbnail
//  53. Title Bar
//  54. Tooltip
//  55. Top Bar
//  56. Xy Grid

@import 'util/util';

// 1. Global
// ---------

$global-font-size                        : 100%;
$global-width                            : rem-calc(1100);
$global-lineheight                       : 1.6;
@import "colors";
$body-background                         : $white;
$body-font-color                         : $dark-gray;
$body-font-family                        : "HindSiliguri", "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
$body-antialiased                        : true;
$global-margin                           : 1rem;
$global-padding                          : 1rem;
$global-position                         : 1rem;
$global-weight-normal                    : normal;
$global-weight-bold                      : bold;
$global-radius                           : 0;
$global-menu-padding                     : 0.7rem rem-calc(14);
$global-menu-nested-margin               : 1rem;
$global-text-direction                   : ltr;
$global-flexbox                          : true;
$global-prototype-breakpoints            : false;
$global-button-cursor                    : auto;
$global-color-pick-contrast-tolerance    : 0;
$print-transparent-backgrounds           : true;
$print-hrefs                             : true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints                             : (
        small: 0,
        medium: 640px,
        large: 1024px,
        xlarge: 1200px,
        xxlarge: 1440px,
);
$breakpoints-hidpi                       : (
        hidpi-1: 1,
        hidpi-1-5: 1.5,
        hidpi-2: 2,
        retina: 2,
        hidpi-3: 3
);
$print-breakpoint                        : large;
$breakpoint-classes                      : (small medium large xlarge);

// 3. The Grid
// -----------

$grid-row-width                          : $global-width;
$grid-column-count                       : 12;
$grid-column-gutter                      : (
        small: 20px,
        medium: 30px,
);
$grid-column-align-edge                  : true;
$grid-column-alias                       : 'columns';
$block-grid-max                          : 8;

// 4. Base Typography
// ------------------

$header-font-family                      : $body-font-family;
$header-font-weight                      : 600; // $global-weight-normal;
$header-font-style                       : normal;
$font-family-monospace                   : Consolas, 'Liberation Mono', Courier, monospace;
$header-color                            : inherit;
$header-lineheight                       : 1.6;
$header-margin-bottom                    : 0.8rem;
$header-styles                           : (
        small: (
                'h1': ('font-size': 35),
                'h2': ('font-size': 20),
                'h3': ('font-size': 20),
                'h4': ('font-size': 18),
                'h5': ('font-size': 18),
                'h6': ('font-size': 18),
        ),
        medium: (
                'h1': ('font-size': 40),
                'h2': ('font-size': 22),
                'h3': ('font-size': 22),
                'h4': ('font-size': 18),
                'h5': ('font-size': 18),
                'h6': ('font-size': 18),
        ),
);
$header-text-rendering                   : optimizeLegibility;
$small-font-size                         : 80%;
$header-small-font-color                 : $dark-gray;
$paragraph-lineheight                    : rem-calc(22); //1.6;
$paragraph-margin-bottom                 : 1rem;
$paragraph-text-rendering                : optimizeLegibility;
$enable-code-inline                      : true;
$anchor-color                            : $primary-color;
$anchor-color-hover                      : scale-color($anchor-color, $lightness : -14%);
$anchor-text-decoration                  : none;
$anchor-text-decoration-hover            : none;
$hr-width                                : $global-width;
$hr-border                               : 1px solid $medium-gray;
$hr-margin                               : rem-calc(20) auto;
$list-lineheight                         : $paragraph-lineheight;
$list-margin-bottom                      : $paragraph-margin-bottom;
$list-style-type                         : disc;
$list-style-position                     : outside;
$list-side-margin                        : 1.25rem;
$list-nested-side-margin                 : 1.25rem;
$defnlist-margin-bottom                  : 1rem;
$defnlist-term-weight                    : $global-weight-bold;
$defnlist-term-margin-bottom             : 0.3rem;
$blockquote-color                        : $dark-gray;
$blockquote-padding                      : rem-calc(9 20 0 19);
$blockquote-border                       : 1px solid $medium-gray;
$enable-cite-block                       : true;
$keystroke-font                          : $font-family-monospace;
$keystroke-color                         : $black;
$keystroke-background                    : $light-gray;
$keystroke-padding                       : rem-calc(2 4 0);
$keystroke-radius                        : $global-radius;
$abbr-underline                          : 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size                          : $global-font-size * 1.25;
$lead-lineheight                         : 1.6;
$subheader-lineheight                    : 1.4;
$subheader-color                         : $dark-gray;
$subheader-font-weight                   : $global-weight-normal;
$subheader-margin-top                    : 0.2rem;
$subheader-margin-bottom                 : 0.5rem;
$stat-font-size                          : 2.5rem;
$cite-color                              : $dark-gray;
$cite-font-size                          : rem-calc(13);
$cite-pseudo-content                     : '\2014 \0020';
$code-color                              : $black;
$code-font-family                        : $font-family-monospace;
$code-font-weight                        : $global-weight-normal;
$code-background                         : $light-gray;
$code-border                             : 1px solid $medium-gray;
$code-padding                            : rem-calc(2 5 1);
$code-block-padding                      : 1rem;
$code-block-margin-bottom                : 1.5rem;

// 6. Abide
// --------

$abide-inputs                            : true;
$abide-labels                            : true;
$input-background-invalid                : get-color(alert);
$form-label-color-invalid                : get-color(alert);
$input-error-color                       : get-color(alert);
$input-error-font-size                   : rem-calc(12);
$input-error-font-weight                 : $global-weight-bold;

// 7. Accordion
// ------------

$accordion-background                    : $light-gray; //$white;
$accordion-plusminus                     : true;
$accordion-plus-content                  : '\002B';
$accordion-minus-content                 : '\2013';
$accordion-title-font-size               : rem-calc(12);
$accordion-item-color                    : $primary-color;
$accordion-item-background-hover         : $light-gray;
$accordion-item-padding                  : 1.25rem 1rem;
$accordion-content-background            : $white;
$accordion-content-border                : 1px solid $light-gray;
$accordion-content-color                 : $body-font-color;
$accordion-content-padding               : 1rem;

// 8. Accordion Menu
// -----------------

$accordionmenu-padding                   : $global-menu-padding;
$accordionmenu-nested-margin             : $global-menu-nested-margin;
$accordionmenu-submenu-padding           : $accordionmenu-padding;
$accordionmenu-arrows                    : true;
$accordionmenu-arrow-color               : $primary-color;
$accordionmenu-item-background           : null;
$accordionmenu-border                    : null;
$accordionmenu-submenu-toggle-background : null;
$accordion-submenu-toggle-border         : $accordionmenu-border;
$accordionmenu-submenu-toggle-width      : 40px;
$accordionmenu-submenu-toggle-height     : $accordionmenu-submenu-toggle-width;
$accordionmenu-arrow-size                : 6px;

// 9. Badge
// --------

$badge-background                        : $primary-color;
$badge-color                             : $white;
$badge-color-alt                         : $black;
$badge-palette                           : $foundation-palette;
$badge-padding                           : 0.3em;
$badge-minwidth                          : 2.1em;
$badge-font-size                         : 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin                      : 0 0 $global-margin 0;
$breadcrumbs-item-font-size              : rem-calc(11);
$breadcrumbs-item-color                  : $primary-color;
$breadcrumbs-item-color-current          : $black;
$breadcrumbs-item-color-disabled         : $medium-gray;
$breadcrumbs-item-margin                 : 0.75rem;
$breadcrumbs-item-uppercase              : true;
$breadcrumbs-item-separator              : true;
$breadcrumbs-item-separator-item         : '/';
$breadcrumbs-item-separator-item-rtl     : '\\';
$breadcrumbs-item-separator-color        : $medium-gray;

// 11. Button
// ----------

$button-font-family                      : inherit;
$button-font-weight                      : null;
$button-padding                          : rem-calc(15 26);
$button-margin                           : 0 0 $global-margin 0;
$button-fill                             : solid;
$button-background                       : $primary-color;
$button-background-hover                 : scale-color($button-background, $lightness : -15%);
$button-color                            : $white;
$button-color-alt                        : $black;
$button-radius                           : 40px;
$button-border                           : 1px solid transparent;
$button-hollow-border-width              : 1px;
$button-sizes                            : (
        tiny: 0.6rem,
        small: 0.75rem,
        default: 1rem, //0.9rem,
        large: 1.25rem,
);
$button-palette                          : $foundation-palette;
$button-opacity-disabled                 : 0.25;
$button-background-hover-lightness       : -20%;
$button-hollow-hover-lightness           : -50%;
$button-transition                       : background-color 0.25s ease-out, color 0.25s ease-out;
$button-responsive-expanded              : false;

// 12. Button Group
// ----------------

$buttongroup-margin                      : 1rem;
$buttongroup-spacing                     : 1px;
$buttongroup-child-selector              : '.button';
$buttongroup-expand-max                  : 6;
$buttongroup-radius-on-each              : true;

// 13. Callout
// -----------

$callout-background                      : $white;
$callout-background-fade                 : 85%;
$callout-border                          : 1px solid rgba($black, 0.25);
$callout-margin                          : 0 0 1rem 0;
$callout-sizes                           : (
        small: 0.5rem,
        default: 1rem,
        large: 3rem,
);
$callout-font-color                      : $body-font-color;
$callout-font-color-alt                  : $body-background;
$callout-radius                          : $global-radius;
$callout-link-tint                       : 30%;

// 14. Card
// --------

$card-background                         : $light-gray; //$white;
$card-font-color                         : $body-font-color;
$card-divider-background                 : $light-gray;
$card-border                             : none; //1px solid $light-gray;
$card-shadow                             : none;
$card-border-radius                      : 0; //$global-radius;
$card-padding                            : rem-calc(20); //$global-padding;
$card-margin-bottom                      : $global-margin;

// 15. Close Button
// ----------------

$closebutton-position                    : right top;
$closebutton-z-index                     : 10;
$closebutton-default-size                : medium;
$closebutton-offset-horizontal           : (
        small: 0.66rem,
        medium: 1rem,
);
$closebutton-offset-vertical             : (
        small: 0.33em,
        medium: 0.5rem,
);
$closebutton-size                        : (
        small: 1.5em,
        medium: 2em,
);
$closebutton-lineheight                  : 1;
$closebutton-color                       : $dark-gray;
$closebutton-color-hover                 : $black;

// 16. Drilldown
// -------------

$drilldown-transition                    : transform 0.15s linear;
$drilldown-arrows                        : true;
$drilldown-padding                       : $global-menu-padding;
$drilldown-nested-margin                 : 0;
$drilldown-background                    : $white;
$drilldown-submenu-padding               : $drilldown-padding;
$drilldown-submenu-background            : $white;
$drilldown-arrow-color                   : $primary-color;
$drilldown-arrow-size                    : 6px;

// 17. Dropdown
// ------------

$dropdown-padding                        : 1rem;
$dropdown-background                     : $body-background;
$dropdown-border                         : 1px solid $medium-gray;
$dropdown-font-size                      : 1rem;
$dropdown-width                          : 300px;
$dropdown-radius                         : $global-radius;
$dropdown-sizes                          : (
        tiny: 100px,
        small: 200px,
        large: 400px,
);

// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows                     : true;
$dropdownmenu-arrow-color                : $anchor-color;
$dropdownmenu-arrow-size                 : 6px;
$dropdownmenu-arrow-padding              : 1.5rem;
$dropdownmenu-min-width                  : 200px;
$dropdownmenu-background                 : null;
$dropdownmenu-submenu-background         : $white;
$dropdownmenu-padding                    : $global-menu-padding;
$dropdownmenu-nested-margin              : 0;
$dropdownmenu-submenu-padding            : $dropdownmenu-padding;
$dropdownmenu-border                     : 1px solid $medium-gray;
$dropdown-menu-item-color-active         : get-color(primary);
$dropdown-menu-item-background-active    : transparent;

// 19. Flexbox Utilities
// ---------------------

$flex-source-ordering-count              : 6;
$flexbox-responsive-breakpoints          : true;

// 20. Forms
// ---------

$fieldset-border                         : 1px solid $medium-gray;
$fieldset-padding                        : rem-calc(20);
$fieldset-margin                         : rem-calc(18 0);
$legend-padding                          : rem-calc(0 3);
$form-spacing                            : rem-calc(16);
$helptext-color                          : $black;
$helptext-font-size                      : rem-calc(13);
$helptext-font-style                     : italic;
$input-prefix-color                      : $black;
$input-prefix-background                 : $light-gray;
$input-prefix-border                     : 1px solid $medium-gray;
$input-prefix-padding                    : 1rem;
$form-label-color                        : $black;
$form-label-font-size                    : rem-calc(14);
$form-label-font-weight                  : $global-weight-normal;
$form-label-line-height                  : 1.8;
$select-background                       : $white;
$select-triangle-color                   : $dark-gray;
$select-radius                           : $global-radius;
$input-color                             : $black;
$input-placeholder-color                 : $medium-gray;
$input-font-family                       : inherit;
$input-font-size                         : rem-calc(16);
$input-font-weight                       : $global-weight-normal;
$input-line-height                       : $global-lineheight;
$input-background                        : $white;
$input-background-focus                  : $white;
$input-background-disabled               : $light-gray;
$input-border                            : 1px solid $medium-gray;
$input-border-focus                      : 1px solid $dark-gray;
$input-padding                           : .5rem 1rem;
$input-shadow                            : inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus                      : 0 0 5px $medium-gray;
$input-cursor-disabled                   : not-allowed;
$input-transition                        : box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners                   : true;
$input-radius                            : 25px;
$form-button-radius                      : $global-radius;

// 21. Label
// ---------

$label-background                        : $primary-color;
$label-color                             : $white;
$label-color-alt                         : $black;
$label-palette                           : $foundation-palette;
$label-font-size                         : 0.8rem;
$label-padding                           : 0.33333rem 0.5rem;
$label-radius                            : $global-radius;

// 22. Media Object
// ----------------

$mediaobject-margin-bottom               : $global-margin;
$mediaobject-section-padding             : $global-padding;
$mediaobject-image-width-stacked         : 100%;

// 23. Menu
// --------

$menu-margin                             : 0;
$menu-nested-margin                      : $global-menu-nested-margin;
$menu-items-padding                      : $global-menu-padding;
$menu-simple-margin                      : 1rem;
$menu-item-color-active                  : $secondary-color;
$menu-item-color-alt-active              : $black;
$menu-item-background-active             : transparent;
$menu-icon-spacing                       : 0.25rem;
$menu-state-back-compat                  : true;
$menu-centered-back-compat               : true;
$menu-icons-back-compat                  : true;

// 24. Meter
// ---------

$meter-height                            : 1rem;
$meter-radius                            : $global-radius;
$meter-background                        : $medium-gray;
$meter-fill-good                         : $success-color;
$meter-fill-medium                       : $warning-color;
$meter-fill-bad                          : $alert-color;

// 25. Off-canvas
// --------------

$offcanvas-sizes                         : (
        small: 250px,
);
$offcanvas-vertical-sizes                : (
        small: 250px,
);
$offcanvas-background                    : $light-gray;
$offcanvas-shadow                        : 0 0 10px rgba($black, 0.7);
$offcanvas-inner-shadow-size             : 20px;
$offcanvas-inner-shadow-color            : rgba($black, 0.25);
$offcanvas-overlay-zindex                : 11;
$offcanvas-push-zindex                   : 12;
$offcanvas-overlap-zindex                : 13;
$offcanvas-reveal-zindex                 : 12;
$offcanvas-transition-length             : 0.5s;
$offcanvas-transition-timing             : ease;
$offcanvas-fixed-reveal                  : true;
$offcanvas-exit-background               : rgba($white, 0.25);
$maincontent-class                       : 'off-canvas-content';

// 26. Orbit
// ---------

$orbit-bullet-background                 : $medium-gray;
$orbit-bullet-background-active          : $dark-gray;
$orbit-bullet-diameter                   : 1.2rem;
$orbit-bullet-margin                     : 0.1rem;
$orbit-bullet-margin-top                 : 0.8rem;
$orbit-bullet-margin-bottom              : 0.8rem;
$orbit-caption-background                : rgba($black, 0.5);
$orbit-caption-padding                   : 1rem;
$orbit-control-background-hover          : rgba($black, 0.5);
$orbit-control-padding                   : 1rem;
$orbit-control-zindex                    : 10;

// 27. Pagination
// --------------

$pagination-font-size                    : rem-calc(14);
$pagination-margin-bottom                : $global-margin;
$pagination-item-color                   : $black;
$pagination-item-padding                 : rem-calc(0 4); //rem-calc(3 10);
$pagination-item-spacing                 : rem-calc(1);
$pagination-radius                       : 0; //$global-radius;
$pagination-item-background-hover        : $primary-color; //$light-gray;
$pagination-item-background-current      : $primary-color;
$pagination-item-color-current           : $white;
$pagination-item-color-disabled          : $light-gray; //$medium-gray;
$pagination-ellipsis-color               : $black;
$pagination-mobile-items                 : false;
$pagination-mobile-current-item          : false;
$pagination-arrows                       : true;
$pagination-arrow-previous               : '\00AB';
$pagination-arrow-next                   : '\00BB';

// 28. Progress Bar
// ----------------

$progress-height                         : 1rem;
$progress-background                     : $medium-gray;
$progress-margin-bottom                  : $global-margin;
$progress-meter-background               : $primary-color;
$progress-radius                         : $global-radius;

// 29. Prototype Arrow
// -------------------

$prototype-arrow-directions              : (
        down,
        up,
        right,
        left
);
$prototype-arrow-size                    : 0.4375rem;
$prototype-arrow-color                   : $black;

// 30. Prototype Border-Box
// ------------------------

$prototype-border-box-breakpoints        : $global-prototype-breakpoints;

// 31. Prototype Border-None
// -------------------------

$prototype-border-none-breakpoints       : $global-prototype-breakpoints;

// 32. Prototype Bordered
// ----------------------

$prototype-bordered-breakpoints          : $global-prototype-breakpoints;
$prototype-border-width                  : rem-calc(1);
$prototype-border-type                   : solid;
$prototype-border-color                  : $medium-gray;

// 33. Prototype Display
// ---------------------

$prototype-display-breakpoints           : $global-prototype-breakpoints;
$prototype-display                       : (
        inline,
        inline-block,
        block,
        table,
        table-cell
);

// 34. Prototype Font-Styling
// --------------------------

$prototype-font-breakpoints              : $global-prototype-breakpoints;
$prototype-wide-letter-spacing           : rem-calc(4);
$prototype-font-normal                   : $global-weight-normal;
$prototype-font-bold                     : $global-weight-bold;

// 35. Prototype List-Style-Type
// -----------------------------

$prototype-list-breakpoints              : $global-prototype-breakpoints;
$prototype-style-type-unordered          : (
        disc,
        circle,
        square
);
$prototype-style-type-ordered            : (
        decimal,
        lower-alpha,
        lower-latin,
        lower-roman,
        upper-alpha,
        upper-latin,
        upper-roman
);

// 36. Prototype Overflow
// ----------------------

$prototype-overflow-breakpoints          : $global-prototype-breakpoints;
$prototype-overflow                      : (
        visible,
        hidden,
        scroll
);

// 37. Prototype Position
// ----------------------

$prototype-position-breakpoints          : $global-prototype-breakpoints;
$prototype-position                      : (
        static,
        relative,
        absolute,
        fixed
);
$prototype-position-z-index              : 975;

// 38. Prototype Rounded
// ---------------------

$prototype-rounded-breakpoints           : $global-prototype-breakpoints;
$prototype-border-radius                 : rem-calc(3);

// 39. Prototype Separator
// -----------------------

$prototype-separator-breakpoints         : $global-prototype-breakpoints;
$prototype-separator-align               : center;
$prototype-separator-height              : rem-calc(2);
$prototype-separator-width               : 3rem;
$prototype-separator-background          : $primary-color;
$prototype-separator-margin-top          : $global-margin;

// 40. Prototype Shadow
// --------------------

$prototype-shadow-breakpoints            : $global-prototype-breakpoints;
$prototype-box-shadow                    : 0 2px 5px 0 rgba(0, 0, 0, .16),
0 2px 10px 0 rgba(0, 0, 0, .12);

// 41. Prototype Sizing
// --------------------

$prototype-sizing-breakpoints            : $global-prototype-breakpoints;
$prototype-sizing                        : (
        width,
        height
);
$prototype-sizes                         : (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%
);

// 42. Prototype Spacing
// ---------------------

$prototype-spacing-breakpoints           : $global-prototype-breakpoints;
$prototype-spacers-count                 : 3;

// 43. Prototype Text-Decoration
// -----------------------------

$prototype-decoration-breakpoints        : $global-prototype-breakpoints;
$prototype-text-decoration               : (
        overline,
        underline,
        line-through,
);

// 44. Prototype Text-Transformation
// ---------------------------------

$prototype-transformation-breakpoints    : $global-prototype-breakpoints;
$prototype-text-transformation           : (
        lowercase,
        uppercase,
        capitalize
);

// 45. Prototype Text-Utilities
// ----------------------------

$prototype-utilities-breakpoints         : $global-prototype-breakpoints;
$prototype-text-overflow                 : ellipsis;

// 46. Responsive Embed
// --------------------

$responsive-embed-margin-bottom          : rem-calc(16);
$responsive-embed-ratios                 : (
        default: 4 by 3,
        widescreen: 16 by 9,
);

// 47. Reveal
// ----------

$reveal-background                       : $white;
$reveal-width                            : 600px;
$reveal-max-width                        : $global-width;
$reveal-padding                          : $global-padding;
$reveal-border                           : none; //1px solid $medium-gray;
$reveal-radius                           : 0; //$global-radius;
$reveal-zindex                           : 1005;
$reveal-overlay-background               : rgba($black, 0.45);

// 48. Slider
// ----------

$slider-width-vertical                   : 0.5rem;
$slider-transition                       : all 0.2s ease-in-out;
$slider-height                           : 0.5rem;
$slider-background                       : $light-gray;
$slider-fill-background                  : $medium-gray;
$slider-handle-height                    : 1.4rem;
$slider-handle-width                     : 1.4rem;
$slider-handle-background                : $primary-color;
$slider-opacity-disabled                 : 0.25;
$slider-radius                           : $global-radius;

// 49. Switch
// ----------

$switch-background                       : $light-gray;
$switch-background-active                : $primary-color;
$switch-height                           : rem-calc(22);
$switch-height-tiny                      : 1.5rem;
$switch-height-small                     : 1.75rem;
$switch-height-large                     : 2.5rem;
$switch-radius                           : 11px;
$switch-margin                           : $global-margin;
$switch-paddle-background                : $primary-color;
$switch-paddle-offset                    : 0.25rem;
$switch-paddle-radius                    : 11px;
$switch-paddle-transition                : all 0.25s ease-out;
$switch-opacity-disabled                 : .5;
$switch-cursor-disabled                  : not-allowed;

// 50. Table
// ---------

$table-background                        : $white;
$table-color-scale                       : 5%;
$table-border                            : 1px solid smart-scale($table-background, $table-color-scale);
$table-padding                           : rem-calc(8 10 10);
$table-hover-scale                       : 2%;
$table-row-hover                         : darken($table-background, $table-hover-scale);
$table-row-stripe-hover                  : darken($table-background, $table-color-scale + $table-hover-scale);
$table-is-striped                        : true;
$table-striped-background                : smart-scale($table-background, $table-color-scale);
$table-stripe                            : even;
$table-head-background                   : smart-scale($table-background, $table-color-scale / 2);
$table-head-row-hover                    : darken($table-head-background, $table-hover-scale);
$table-foot-background                   : smart-scale($table-background, $table-color-scale);
$table-foot-row-hover                    : darken($table-foot-background, $table-hover-scale);
$table-head-font-color                   : $body-font-color;
$table-foot-font-color                   : $body-font-color;
$show-header-for-stacked                 : false;
$table-stack-breakpoint                  : medium;

// 51. Tabs
// --------

$tab-margin                              : 0;
$tab-background                          : $white;
$tab-color                               : $primary-color;
$tab-background-active                   : $light-gray;
$tab-active-color                        : $primary-color;
$tab-item-font-size                      : rem-calc(12);
$tab-item-background-hover               : $white;
$tab-item-padding                        : 1.25rem 1.5rem;
$tab-content-background                  : $white;
$tab-content-border                      : $light-gray;
$tab-content-color                       : $body-font-color;
$tab-content-padding                     : 1rem;

// 52. Thumbnail
// -------------

$thumbnail-border                        : none; //4px solid $white;
$thumbnail-margin-bottom                 : $global-margin;
$thumbnail-shadow                        : none; //0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover                  : 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition                    : box-shadow 200ms ease-out;
$thumbnail-radius                        : 0; //$global-radius;

// 53. Title Bar
// -------------

$titlebar-background                     : $black;
$titlebar-color                          : $white;
$titlebar-padding                        : 0.5rem;
$titlebar-text-font-weight               : bold;
$titlebar-icon-color                     : $white;
$titlebar-icon-color-hover               : $medium-gray;
$titlebar-icon-spacing                   : 0.25rem;

// 54. Tooltip
// -----------

$has-tip-cursor                          : help;
$has-tip-font-weight                     : $global-weight-bold;
$has-tip-border-bottom                   : dotted 1px $dark-gray;
$tooltip-background-color                : $black;
$tooltip-color                           : $white;
$tooltip-padding                         : 0.75rem;
$tooltip-max-width                       : 10rem;
$tooltip-font-size                       : $small-font-size;
$tooltip-pip-width                       : 0.75rem;
$tooltip-pip-height                      : $tooltip-pip-width * 0.866;
$tooltip-radius                          : $global-radius;

// 55. Top Bar
// -----------

$topbar-padding                          : 0.5rem;
$topbar-background                       : $white;
$topbar-submenu-background               : $topbar-background;
$topbar-title-spacing                    : 0.5rem 1rem 0.5rem 0;
$topbar-input-width                      : 200px;
$topbar-unstack-breakpoint               : medium;

// 56. Xy Grid
// -----------

$xy-grid                                 : true;
$grid-container                          : $global-width;
$grid-columns                            : 12;
$grid-margin-gutters                     : (
        small: 20px,
        medium: 30px
);
$grid-padding-gutters                    : $grid-margin-gutters;
$grid-container-padding                  : $grid-padding-gutters;
$grid-container-max                      : $global-width;
$xy-block-grid-max                       : 8;

