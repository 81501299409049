.icons-cmp {
  .icon-container {
    position : relative;
    display  : block;
    margin   : 0 auto 1rem;
    width    : 129px;
    height   : 129px;

    .icon {
      position   : absolute;
      top        : 50%;
      left       : 50%;
      transform  : translate(-50%, -50%);
      max-width  : 64px;
      max-height : 90px;
    }

    .icon-frame {
      position  : absolute;
      top       : 0;
      left      : 50%;
      transform : translate(-50%, 0);
      animation : bouncyIconFrame 4.0s infinite ease-in-out;

    }

    .frame-circle {
      position        : absolute;
      top             : 0;
      left            : 110%;
      transform       : translate(-50%, 0);
      animation       : bouncyIconFrame 4.0s infinite ease-in-out;
      animation-delay : 1.0s;

    }
  }

  p {
    font-weight : 900;
    text-align  : center;
    max-width   : 75%;
    margin      : 0 auto 2rem;
  }
}

@keyframes bouncyIconFrame {
  0%, 100% {
    transform : translate(-50%, 0) scale(0.9);
  }
  50% {
    transform : translate(-50%, 0) scale(1.0);
    opacity   : 0;
  }
}
