.widget-container {
  position : fixed;
  right    : 0;
  top      : 15vh;
  z-index  : 9999;

  .widget {
    background     : $secondary-color;
    padding-left   : rem-calc(12);
    height         : 50px;
    margin-bottom  : 8px;
    display        : flex;
    align-items    : center;
    width          : 180px;
    transform      : translateX(130px);
    transition     : .3s ease-in-out;
    font-weight    : 800;
    color          : $black;
    text-transform : uppercase;

    svg {
      width        : 28px;
      margin-right : 1.5rem;
    }

    &:hover {
      transform : translateX(0);

    }
  }

  @include breakpoint(medium down) {
    display : none;
  }
}



