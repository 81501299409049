#lightcase-case {
  display: none;

  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;

  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  text-shadow: #{map-get($lightcase-settings, 'text-shadow')};

  // Only for type: inline, ajax
  html[data-lc-type=inline] &,
  html[data-lc-type=ajax] & {
    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      margin: 0 !important;
      padding: 55px 0 70px 0;
      width: 100% !important;
      height: 100% !important;
      overflow: auto !important;
    }
  }
}
