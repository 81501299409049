.component-article-archive {
  h3 {
    margin-bottom : 0;
  }

  .caption {
    text-align : left;
  }
  p{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: inherit;
    font-size: smaller;
  }
  .images a figure picture img{
    display: block;
    width:  21rem;
    height: 11rem;
    border-radius: 5px;
    margin-left: 15px;
  }
  .card{
    flex: 0 0 30%;
  }
  .texte a h3 {
    font-size: 15px;
  }
  @media only screen and (max-width: 600px){
    .card{
      flex: 0 0 44%;
    }
    .texte a h3 {
      font-size: 13px;
    }
    .images a figure picture img{
      margin-left: 10px;
    }
  }
  @media only screen and (max-width: 360px){
    .card{
      flex: 0 0 90%;
    }
  }
}
