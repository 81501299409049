.cmp.seminar-filter {
  .divider {
    border-right : 1px solid #87b4c2;
    width        : 1px;
    height       : 38px;
    @include breakpoint(large down) {
      border-right : none;
      height       : 1px;
      width        : 100%;
      margin       : rem-calc(12 0);
    }
  }

  .filter-block {
    display     : flex;
    align-items : center;

    &.no-border {
      border-right : none;
    }

    &.closed-events {
      .big-txt {
        font-size    : rem-calc(18);
        margin-right : rem-calc(16);
      }

      .switch {
        margin : rem-calc(0 16 0 0)
      }
    }

    .custom-checkbox {
      &:last-child {
        padding-right : 0;
      }
    }

    select {
      width                 : 180px;
      height                : 38px;
      margin                : 0 0 0 12px;
      border-color          : transparent;
      background            : $light-gray;
      padding-left          : 12px;
      padding-right         : 12px;
      -moz-appearance       : none; /* Firefox */
      -webkit-appearance    : none; /* Safari and Chrome */
      appearance            : none;
      background-image      : url('{{path href="media/base/img/arrow-down.svg"}}');
      background-repeat     : no-repeat;
      background-position-x : 95%;
      background-position-y : 15px;

      &:after {
        content : url('{{path href="media/base/img/arrow-down.svg"}}');
        display : block;
        width   : 25px;
        height  : 25px;
      }
    }
  }

  hr {
    border-color : #87b4c2;
  }
}
