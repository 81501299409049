header {
  position : relative;
  z-index  : 999;
}

.logo {
  margin-bottom : rem-calc(16);
  @include breakpoint(medium down) {
    width : 130px;
  }
}

.mobile-nav-btn {
  background      : $primary-color;
  width           : 36px;
  height          : 36px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  border-radius   : 18px;
}

.menu-close {
  position : absolute;
  top      : 49px;
  right    : 29px;
}

$navWidth : 100vw;


.mobile-menu {
  position                   : fixed;
  display                    : flex;
  flex-flow                  : column nowrap;
  justify-content            : center;
  top                        : -100vh;
  right                      : -100vw;
  width                      : $navWidth;
  height                     : 100%;
  border-radius              : 100%;
  background                 : $primary-color;
  transition                 : all 1000ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function : cubic-bezier(0.770, 0.000, 0.175, 1.000);
  z-index                    : 10000;
  overflow-y                 : scroll;

  &.active {
    pointer-events : all;
    right          : 0;
    bottom         : 0;
    top            : 0;
    border-radius  : 0;
  }

  .nav__link {
    display         : block;
    font-size       : 25px;
    font-weight     : 800;
    letter-spacing  : -1px;
    text-align      : right;
    padding         : 0;
    text-decoration : none;
    transition      : all .8s cubic-bezier(0.42, 0.0, 0.58, 1.0);
    opacity         : 0;

    a {
      color          : $white;
      padding        : 1vh 5vw;
      text-transform : uppercase;
    }

    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        transition-delay : $i * .09s;
      }
    }

    &.faded {
      opacity : 1;
    }

    &.active {
      a {
        color           : $black;
        text-decoration : underline;
      }
    }
  }

  .logo {
    position  : absolute;
    bottom    : 30px;
    left      : 25px;
    max-width : 218px;
  }

  .menu {
    .vertical {
      position : relative;
      //padding-bottom: rem-calc(25);
    }
  }
}

.top-navigation {
  background     : $white;
  padding-top    : rem-calc(20);
  padding-bottom : rem-calc(20);
  transition     : box-shadow .3s ease-in-out;
  @include breakpoint(large down) {
    padding-bottom : 0;

  }

  &.is-stuck {
    box-shadow : 0px 10px 6px rgba(42, 118, 134, 0.15);
    padding    : 0;
  }
}

.mega-menu {
  display     : flex;
  align-items : center;

  > .menu {
    display  : inline-flex;
    position : inherit;

    li {
      a {
        font-weight    : 600;
        font-size      : rem-calc(16);
        text-transform : uppercase;
        transition     : all .3s ease-in-out;
        @include breakpoint(xlarge down) {
          padding : 0.7rem rem-calc(10)
        }

        &[data-hover]:after {
          content        : url('{{path href="media/base/img/arrow-menu.svg"}}');
          margin-left    : 7px;
          vertical-align : middle;
          transition     : all .3s ease-in-out;
        }

        &:hover {
          color : $secondary-color;

          &[data-hover]:after {
            content : url('{{path href="media/base/img/arrow-menu-hover.svg"}}');
          }
        }
      }
    }
  }

  .mega-menu-submenu {
    display    : none;
    position   : absolute;
    top        : 100%;
    background : $white;
    padding    : rem-calc(40 60 40 40);
    box-shadow : 0px 10px 6px rgba(42, 118, 134, 0.15);

    &.open {
      display  : flex;
      grid-gap : 70px;
    }

    .menu-image {
      width : 310px;

      img {
        width : 100%;
      }
    }

    .menu {
      li {
        border-bottom : 1px solid $medium-gray;
        padding       : rem-calc(10 0);

        &:last-child {
          border-bottom : none;
        }

        a {
          padding : 0;
        }
      }
    }
  }

  a.button {
    font-size      : rem-calc(14);
    margin-left    : rem-calc(7);
    margin-bottom  : 0;
    text-transform : uppercase;
  }
}
