/* ==========================================================================
   #MIXINS
   ========================================================================== */

/* clearfiv
   ========================================================================== */

/**
 * Usage
 *
 *   @extend %clearfix;
 *
 */

.relative{
    position: relative;
}

.v-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.o-hidden{
    overflow: hidden;
}

.flex-bottom{
    display: flex;
    align-items: flex-end;
}



