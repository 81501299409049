h1, h2, h3, h4, h5, h6 {
  //text-transform : uppercase;
  hyphens        : auto;
  font-weight    : 900;
  @include breakpoint(large down) {
    overflow-wrap : break-word;
  }
}

.accent-font {
  font-family : "Baloo2", "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
}

h1 {
  font-family : "Baloo2", "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
  font-weight : 600;
}
