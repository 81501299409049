.reveal-overlay {
  .reveal {
    &#newsletterModalHtml,
    &#newsletterModal {
      top: 50% !important;
      transform: translateY(-50%);
    }
  }
}


#newsletter_form {
  #privacy_policy_accepted {
    position: absolute;
  }
}
