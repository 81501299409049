.cmp-faq{
    a.accordion-title{
        border-radius: 0;
        &:focus{
            background-color: $white;
        }
    }
    .accordion-item{
        &:first-child>:first-child{
            border-radius: 0;
        }
        &:last-child:not(.is-active)>.accordion-title{
            border-radius: 0;
        }
        margin-bottom: rem-calc(25);
        border: 1px solid $secondary-color;
        transition: all .3s ease-in-out;
        .accordion-title{
            color: $secondary-color;
            font-weight: bold;
            text-transform: uppercase;
            transition: all .3s ease-in-out;
            &:hover{
                color: $white;
                background-color: $secondary-color;

            }
        }
        &.is-active{
            box-shadow: rgba(124,116,116,0.2) 0px 2px 2px, rgba(124,116,116,0.2) 0px 4px 4px, rgba(124,116,116,0.2) 0px 8px 8px, rgba(124,116,116,0.2) 0px 16px 16px, rgba(124,116,116,0.2) 0px 32px 32px, rgba(124,116,116,0.2) 0px 64px 64px;
            .accordion-title{
                color: $white;
                background-color: $secondary-color;

            }
        }

        .cmp.downloads{
            padding: 0;
        }
    }

}
