.pagination {
  margin-bottom : rem-calc(35);

  .disabled {
    padding : 0;
    opacity : 0;
  }


  .previous, .next {
    a {
      padding       : rem-calc(5 9 5 15) !important;
      border-radius : 15px !important;
      margin        : rem-calc(0 15);

      &:hover {
        background-color : $primary-color;
      }
    }

  }

}
