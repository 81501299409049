/* ==========================================================================
   #Fonts
   ========================================================================== */

/* innogy-Fonts vom UI-Kit uebernommen
   ========================================================================== */

/*@font-face {
    font-family: exlight;
    src: url('{{path href="media/dist/fonts/baloo_2/innogy/innogyWEB-Light?#iefix"}}') format("eot"), url('{{path href="media/dist/fonts/baloo_2/innogy/innogyWEB-Light.woff2"}}') format("woff2"), url('{{path href="media/dist/fonts/baloo_2/innogy/innogyWEB-Light.woff"}}') format("woff"), url('{{path href="media/dist/fonts/baloo_2/innogy/innogyWEB-Light.svg"}}') format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-Light.ttf"}}') format("truetype");
    font-weight: 200;
    font-style: normal
}

@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-Regular.ttf"}}') format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-Medium.ttf"}}') format("truetype");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-SemiBold.ttf"}}') format("truetype");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: Prata;
    src: url('{{path href="media/base/fonts/baloo_2/Prata-Regular.ttf"}}') format("truetype");
    font-weight: 900;
    font-style: normal
}
*/
/*
!* Icon-Font aus UI-Kit
   ========================================================================== *!
@font-face {
    font-family: iconfont;
    src: url('{{path href="media/base/dist/fonts/baloo_2/innogy-iconfont/iconfont.eot"}}');
    src: url('{{path href="media/base/dist/fonts/baloo_2/innogy-iconfont/iconfont.eot?#iefix"}}') format("eot"), url('{{path href="media/base/dist/fonts/baloo_2/innogy-iconfont/iconfont.woff"}}') format("woff"), url('{{path href="media/base/dist/fonts/baloo_2/innogy-iconfont/iconfont.ttf"}}') format("truetype"), url('{{path href="media/base/dist/fonts/baloo_2/innogy-iconfont/iconfont.svg#iconfont"}}') format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'sbIfont';
    src: url('{{path href="media/base/fonts/baloo_2/icons/icons.eot"}}');
    src: url('{{path href="media/base/fonts/baloo_2/icons/icons.eot?#iefix"}}') format('embedded-opentype'),
    url('{{path href="media/base/fonts/baloo_2/icons/icons.woff2"}}') format('woff2'),
    url('{{path href="media/base/fonts/baloo_2/icons/icons.woff"}}') format('woff'),
    url('{{path href="media/base/fonts/baloo_2/icons/icons.ttf"}}') format('truetype'),
    url('{{path href="media/base/fonts/baloo_2/icons/icons.otf"}}') format('opentype');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-Regular.ttf"}}') format("truetype");
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-Medium.ttf"}}') format("truetype");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-SemiBold.ttf"}}') format("truetype");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-Bold.ttf"}}') format("truetype");
    font-weight: 700;
    font-style: normal
}
@font-face {
    font-family: Baloo2;
    src: url('{{path href="media/base/fonts/baloo_2/Baloo2-ExtraBold.ttf"}}') format("truetype");
    font-weight: 900;
    font-style: normal
}

// hind_siliguri
@font-face {
    font-family: HindSiliguri;
    src: url('{{path href="media/base/fonts/hind_siliguri/HindSiliguri-Light.ttf"}}') format("truetype");
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: HindSiliguri;
    src: url('{{path href="media/base/fonts/hind_siliguri/HindSiliguri-Regular.ttf"}}') format("truetype");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: HindSiliguri;
    src: url('{{path href="media/base/fonts/hind_siliguri/HindSiliguri-Medium.ttf"}}') format("truetype");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: HindSiliguri;
    src: url('{{path href="media/base/fonts/hind_siliguri/HindSiliguri-SemiBold.ttf"}}') format("truetype");
    font-weight: 700;
    font-style: normal
}
@font-face {
    font-family: HindSiliguri;
    src: url('{{path href="media/base/fonts/hind_siliguri/HindSiliguri-Bold.ttf"}}') format("truetype");
    font-weight: 900;
    font-style: normal
}
