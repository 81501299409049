body {
  overflow-x : hidden;
}

/* ie11 img flex fix*/
img {
  max-width   : 100%;
  flex-shrink : 0;
}

.txt-third {
  color : $third-color;
}

.hp-layout {
  display : none;
}

.cmp {
  margin-bottom : rem-calc(35);
}

.relative {
  position : relative;
}

.hidden {
  display : none;
}

.link-ellipse {
  display        : inline-block;
  overflow-x     : hidden;
  text-overflow  : ellipsis;
  vertical-align : text-top;
  max-width      : 65%;
  white-space    : nowrap;
  cursor         : pointer;
}

.list-unstyled {
  list-style  : none;
  margin-left : 0;
}

table {
  width : 100% !important;

  td {
    vertical-align : top;
  }

  table {
    margin        : 0;
    border-radius : 0;

    tbody {
      border           : none;
      background-color : transparent;

      tr:nth-child(odd), tbody tr:nth-child(even) {
        background-color : transparent;
      }

      td {
        padding : 0;
      }
    }
  }
}

dl {
  margin  : 0;
  padding : 0;
  @media screen and (min-width : 640px) {
    display   : flex;
    flex-wrap : wrap;
  }
}

dt {
  margin      : .5em 2% 0 0;
  padding     : 0;
  font-weight : bold;
  @media screen and (min-width : 640px) {
    width   : 20%;
    display : inline-block;
  }
}

dd {
  margin  : .5em 0 .5em;
  padding : 0;
  @media screen and (min-width : 640px) {
    width   : 75%;
    display : inline-block;
  }
}

.thumbnail figcaption {
  display : none;
}
