.cmp-event-infos {
  p {
    @include breakpoint(large) {
      column-count  : 2;
      margin-bottom : 2rem;
    }
  }

  .ev-info-item {
    width           : 168px;
    height          : 168px;
    border          : 1px solid $light-gray;
    border-radius   : 85px;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    text-align      : center;
    font-size       : rem-calc(14);
    margin-bottom   : 1rem;

    .count {
      font-family : "Baloo2";
      font-size   : rem-calc(70);
      line-height : 1;
      color       : $third-color;
    }
  }
}
