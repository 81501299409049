.partner {
  img {
    filter         : gray;
    -webkit-filter : grayscale(1);
    filter         : grayscale(1);
    margin-bottom  : .3rem;
  }

  h4 {
    font-size      : rem-calc(18);
    text-transform : initial;
  }
}



