[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
  height : 2.8rem;

  &:focus {
    &::placeholder {
      opacity : 0.3;
    }
  }
}

select {
  height        : 2.8rem;
  line-height   : 1.5;
  border-radius : 25px;
}

[type='submit'] {
  display            : inline-block;
  vertical-align     : middle;
  margin             : 0 0 1rem 0;
  font-family        : inherit;
  //font-weight        : bold;
  padding            : $button-padding;
  -webkit-appearance : none;
  border             : 1px solid transparent;
  border-radius      : 6px;
  transition         : background-color 0.25s ease-out, color 0.25s ease-out;
  font-size          : 1rem;
  line-height        : 1;
  text-align         : center;
  cursor             : pointer;
  background-color   : $button-background;
  color              : $button-color;
}

/* Formbuilder - Formular
--------------------------------------------------------------------------------------------------------------------- */

/* Standard */
.component-form .control-group {
  display        : inline-block;
  margin-bottom  : 15px;
  width          : 46%;
  vertical-align : top;
  padding-right  : 15px;
  box-sizing     : border-box;
}

.component-form .control-group label {
  display : block;
}

.component-form .control-group input[type="text"],
.component-form .control-group select,
.component-form .control-group textarea,
.component-form .control-group input[type="file"],
.component-form .control-group input[type="email"] {
  display : block;
  width   : 100%;
}

.component-form .control-group input[type="file"] {
  // border: 1px solid $light-gray;
  //padding: 5px
}

/* Textarea */

.component-form .control-group.formo-textarea {
  display : block;

}

.component-form .control-group textarea {
  width  : 100%;
  height : 200px;
}

/* Honey-Pot */
.hp-layout,
aside .hp-layout {
  display : none !important;
}


/* Errors */

.error input,
.error textarea,
.error select {
  border     : 1px solid #d10000 !important;
  box-shadow : 0 0 3px #d10000 !important;
}

.error .opt-label {
  color : #d10000 !important;
}

/* Submit Button */

.component-form .control-group input[type="submit"],
.component-accomodation-request input[type="submit"] {
  display        : inline-block;
  text-align     : center;
  white-space    : nowrap;
  vertical-align : middle;
  border-radius  : 6px;
  cursor         : pointer;

}

/* Captcha Field */

#recaptcha_widget_div {
  margin-bottom : 15px;
}

.recaptchatable,
.recaptchatable td,
.recaptchatable input {
  width : 100% !important;
}

.recaptchatable .recaptcha_image_cell #recaptcha_image {
  border : 0 none !important;
}

@media (max-width : 768px) {
  .component-form .control-group {
    display : block;
    width   : 100%;
  }
  .component-form .control-group input[type="text"],
  .component-form .control-group select,
  .component-form .control-group.formo-textarea,
  .component-form .control-group textarea,
  .component-form .control-group input[type="submit"],
  .component-accomodation-request input[type="submit"] {
    width : 100%;
  }
  .component-form .control-group input[type="submit"],
  .component-accomodation-request input[type="submit"] {
    margin-top : 10px;
  }
}

/* Formular in Sidebar */

aside .component-form .control-group,
aside .component-form .control-group input[type="text"],
aside .component-form .control-group select {
  width : 100%;
}

/* Textarea */

.component-form .control-group.formo-textarea {
  display : block;
  width   : 96.2%;
}

.component-form .control-group textarea {
  width  : 100%;
  height : 200px;
}

label {
  padding-left : 1rem;
}

input[type="checkbox"] {
  margin-top : rem-calc(5);
}

input:checked ~ .switch-paddle::after {
  background-color : $white;
}

.custom-checkbox {
  display               : grid;
  grid-template-columns : 1em auto;
  gap                   : 0.5em;
  align-items           : center;
  padding-left          : 0;
  padding-right         : 12px;

  input[type="checkbox"] {
    display            : grid;
    place-content      : center;
    -webkit-appearance : none;
    appearance         : none;
    background-color   : #ffffff;
    margin             : 0;
    width              : 15px;
    height             : 15px;
    border             : 1px solid $primary-color;
    border-radius      : 0;
    transform          : translateY(-0.075em);

    &:before {
      content    : url('{{path href="media/base/img/checkmark.svg"}}');
      width      : 0.65em;
      height     : 0.65em;
      transform  : scale(0);
      transition : 120ms transform ease-in-out;
      //box-shadow : inset 1em 1em $primary-color;
    }

    &:checked::before {
      transform : scale(1);
    }
  }
}

.custom-checkbox + .custom-checkbox {
  // margin-top : 1em;
}
