@keyframes Gradient {
  0% {
    background-position : 0% 50%;
    color               : $white;
  }
  50% {
    background-position : 100% 50%;
    color               : $primary-color;
  }
  100% {
    background-position : 0% 50%;
  }
}

@keyframes pulse {
  0% {
    box-shadow : 0px 0px 0px 0px rgba($primary-color, 0.5);
  }
  100% {
    box-shadow : 0px 0px 0px 10px rgba(97, 183, 34, 0.0);
  }
}

@keyframes bouncy {
  0%, 100% {
    transform : scale(0.8);
  }
  50% {
    transform : scale(1.0);
    opacity   : 0;
  }
}

