.cmp.contact-cta {
  color       : $white;
  padding-top : 80px;

  .color-box {
    @include breakpoint(large) {
      background : $primary-color;
    }
  }

  .txt-container {
    padding    : rem-calc(65 0 40 65);
    background : $primary-color;

    svg {
      position  : absolute;
      top       : 60px;
      left      : 85%;
      animation : bouncy 4.0s infinite ease-in-out;
      @include breakpoint(medium down) {
        top  : 36%;
        left : 25px;
      }

      &.circle-small {
        left            : 107%;
        top             : 38px;
        animation-delay : 1.0s;
        @include breakpoint(medium down) {
          left : 42%;
          top  : 28%;
        }
      }
    }
  }

  .contact-img {
    margin-top : 0;
    @include breakpoint(large) {
      transform: scale(1.3) translateY(-11.6%);
      position: absolute;
      bottom: 0;
      //margin-top : -70px;
    }
    //@include breakpoint(xlarge) {
    //  margin-top : -71px;
    //}
  }
}
