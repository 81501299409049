footer {
  font-size : rem-calc(12);

  .txt-third {
    margin-right : rem-calc(28);
  }

  .nav {
    display         : flex;
    grid-gap        : 25px;
    list-style      : none;
    text-align      : left;
    justify-content : space-between;

    > li > a {
      display        : block;
      pointer-events : none;
      color          : $black;
      font-weight    : 800;
      margin-bottom  : .7rem;
    }

    ul {
      list-style  : none;
      margin-left : 0;

      li {
        a {
          color : $body-font-color;
        }
      }
    }

    @include breakpoint(medium down) {
      flex-direction : column;
      text-align     : center;
    }
  }

  .social-media {
    @include breakpoint(large) {
      margin-left : rem-calc(60);
    }
    @include breakpoint(medium down) {
      text-align : center;
    }
  }
}

.copyright {
  text-align    : center;
  margin-bottom : 2rem;
  font-size     : rem-calc(12);

  hr {
    width         : 60px;
    border-color  : $medium-gray;
    margin-bottom : rem-calc(12);
  }
}
