#lightcase-case {

  p.lightcase-error {
    margin: 0;
    font-size: 17px;
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #aaa;

    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
      padding: 30px 0;
    }

    @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
      padding: 0;
    }
  }

}