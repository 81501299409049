.cmp-page-row {
  .highlight-hover {
    transition : all .3s ease-in-out;

    &:hover {
      transform  : scale(1.02);
      box-shadow : 0px 10px 6px rgba(42, 118, 134, 0.15);
    }
  }

  .height-even {
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    .bottom {
      margin-top: auto;
    }
  }

  .gray-box {
    background-color : $light-gray;
    padding          : rem-calc(30 40);

    .subline {
      color       : $primary-color;
      font-size   : rem-calc(14);
      line-height : 1;
    }

    h2 {
      font-weight : 900;
      font-size   : rem-calc(22);
    }

    .button {
      margin-bottom : 0;
    }
  }

  &.highlight {
    //.large-up-4 {
    .gray-box {
      padding        : rem-calc(0 0 15 0);
      text-align     : center;
      height         : 100%;
      display        : flex;
      flex-direction : column;
    }

    .header {
      background      : $secondary-color;
      padding         : rem-calc(15 15 5 15);
      display         : flex;
      flex-direction  : column;
      flex-flow       : column-reverse;
      height          : 100px;
      justify-content : flex-end;

      h2 {
        color  : $black;
        margin : 0;
      }

      .subline {
        color       : $body-font-color;
        font-size   : rem-calc(14);
        font-weight : 900;
        height: calc(3 * #{rem-calc(14)} );
        overflow: hidden;
      }
    }

    .inner-content {
      padding : rem-calc(25);

      ul {
        list-style : none;
        margin     : 0;
        padding    : 0;

        li {
          &:after {
            content       : '';
            width         : 10px;
            height        : 10px;
            background    : $medium-gray;
            border-radius : 12px;
            margin        : 12px auto;
            display       : block;
          }

          &:last-child {
            &:after {
              display : none;
            }
          }
        }
      }
    }

    .button.primary.outlined {
      padding     : rem-calc(15 0 0);
      border      : 0;
      font-weight : 900;

      &:hover {
        background : transparent;
        color      : $black;
      }
    }
    //}
  }
}


