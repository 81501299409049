.cmp.seminar-teaser {
  .seminar-infos {
    margin     : rem-calc(55 0);
    background : $white;
    padding    : rem-calc(25 55 35);
    @include breakpoint(small only) {
      background : rgba($white, .8);
      margin     : rem-calc(25);
    }

    .date {
      background    : $secondary-color;
      padding       : rem-calc(0 12);
      display       : inline-block;
      margin-bottom : 1rem;
    }

    h4 {
      color          : $primary-color;
      text-transform : initial;
    }

    p {
      margin-bottom : 1rem;
    }

    small {
      margin-bottom : rem-calc(4);
      display       : block;
    }

    .button {
      text-transform : uppercase;
      color          : $primary-color;
      font-size      : rem-calc(14);
    }
  }
}
