$foundation-palette: (
    primary: #2A7686,
    secondary: #F0DF05,
    third: #87B4C2,
    success: #3adb76,
    warning: #ffae00,
    alert: #cc4b37,
);
$third-color: get-color(third);
$light-gray: #F4F4F4;
$medium-gray: #CCD1D9;
$dark-gray: #4D4D4D;
$black: #000;
$white: #fff;

@include add-foundation-colors;
