button, .button {
  font-weight : 600;

  &.primary.outlined {
    background : transparent;
    border     : 1px solid $primary-color;
    color      : $primary-color;

    &:hover {
      background : $primary-color;
      color      : $white
    }
  }

  &.secondary.outlined {
    background : transparent;
    border     : 1px solid $secondary-color;
    color      : $secondary-color;

    &:hover {
      background : $secondary-color;
      color      : $black
    }
  }
}
